import {BlurFilter, Container} from "pixi.js";

export class BaseGameScene extends Container {
    constructor(game) {
        super();
        this.game = game;
    }

    showBlur() {
        this.filters = [new BlurFilter()];
    }

    hideBlur() {
        this.filters = [];
    }

    disable() {

    }
}
