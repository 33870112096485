import {Loading} from "../states/Loading";
import {PlayRound} from "../states/PlayRound";
import {WinRound} from "../states/WinRound";
import {LoseRound} from "../states/LoseRound";
import {WinAllRounds} from "../states/WinAllRounds";
import {WaitKick} from "../states/WaitKick";
import {WaitKickResult} from "../states/WaitKickResult";
import {CollectWinning} from "../states/CollectWinning";
import {SelectBet} from "../states/SelectBet";
import {Info} from "../poups/Info";
import {Bets} from "../poups/Bets";
import {WaitingForBet} from "../states/WaitingForBet";
import {GamePlay} from "../scenes/GamePlay";
import {SettingGameSettings} from "../states/SettingGameSettings";
import {Settings} from "../poups/Settings";
import {LoadingScene} from "../scenes/Loading";
import {Lobby} from "../scenes/Lobby";
import {SelectLevel} from "../states/SelectLevel";
import {Autoplay} from "../poups/Autoplay";
import {AutoplaySettings} from "../states/AutoplaySettings";
import {ShowInfo} from "../states/ShowInfo";
import {Restore} from "../states/Restore";
import {SessionExpiredPopup} from "../poups/SessionExpiredPopup";
import {NotEnoughFundsPopup} from "../poups/NotEnoughFundsPopup";
import {ErrorsState} from "../states/ErrorsState";
import {ErrorMessagePopup} from "../poups/ErrorMessagePopup";
import {DisabledState} from "../states/DisabledState";

export const gameConfig = {
    screenSize: {
        width: 1220,
        height: 820
    },
    default: {
        betsLis: [0.05, 0.25, 0.5, 1, 2, 5, 7.5, 10, 25, 50, 100],
        balance: 1000
    },
    autoplay: {
        minBet: 0.5,
        maxBet: 100,
        step: 0.5
    },
    autoplayClickTime: 1500,
    states: {
        'load-screen': Loading,
        'waitingForBet': WaitingForBet,
        'playRound': PlayRound,
        'winRound': WinRound,
        'loseRound': LoseRound,
        'winAllRounds': WinAllRounds,
        'waitKick': WaitKick,
        'waitKickResult': WaitKickResult,
        'collectWinning': CollectWinning,
        'selectBet': SelectBet,
        'settingGameSettings': SettingGameSettings,
        'selectLevel': SelectLevel,
        'autoplaySettings': AutoplaySettings,
        'showInfo': ShowInfo,
        'restore': Restore,
        'errorsState': ErrorsState,
        'disabledState': DisabledState,
    },
    scenes: {
        'gamePlay': GamePlay,
        'loading': LoadingScene,
        'lobby': Lobby,
    },
    popups: {
        'Info': Info,
        'Bets': Bets,
        'Settings': Settings,
        'Autoplay': Autoplay,
        'SessionExpiredPopup': SessionExpiredPopup,
        'NotEnoughFundsPopup': NotEnoughFundsPopup,
        'ErrorMessagePopup': ErrorMessagePopup,
    },
    cups: [
        {
            texture: 'Cup2',
            label: 'World Cup',
            multiplier: 8,
            locked: true
        }, {
            texture: 'Cup4',
            label: 'Nation League',
            multiplier: 4,
            locked: false
        }, {
            texture: 'Cup3',
            label: 'European Cup',
            multiplier: 2,
            locked: true
        }
    ],
    counties:    ['France', 'Brazil', 'Germany', 'Sweden', 'Spain', 'Ukraine', 'Argentina']
}
