import {BlurFilter, Container} from "pixi.js";
import {GameProgressBar} from "../objects/GameProgressBar";
import {InfoLabel} from "../objects/InfoLabel";
import {ObjectFactory} from "../ObjectFactory";
import {GateTargets} from "../objects/GateTargets";
import {Clock} from "../objects/Clock";
import {BetsListTextStyle, BottomPanelTextStyle} from "../configs/textStyles";
import {formatCurrency} from "../utils";
import {Goalkeeper} from "../objects/Goalkeeper";
import {Timer} from "../Timer";
import {AutoplayButton} from "../objects/AutoplayButton";
import ValueTween from "../ValueTween";
import {BaseGameScene} from "./BaseGameScene";

export class GamePlay extends BaseGameScene {
    constructor(game) {
        super(game);

        this.animationSpeed = 1;

        const bg = this.game.objectFactory.createSpriteFromSheet('bg.png');
        this.addChild(bg);

        this.lights = this.game.objectFactory.createLightAnimation();
        this.lights.startRandomAnimations();
        this.addChild(this.lights);

        this.clock = new Clock();
        this.clock.x = 48;
        this.clock.y = 42;
        this.addChild(this.clock);

        this.flag = ObjectFactory.createSpriteFromSheet(`${this.game.settings.country}.png`);
        this.flag.x = this.game.config.screenSize.width / 2;
        this.flag.y = 80;
        this.flag.anchor.set(0.5);
        this.addChild(this.flag);

        this.gameProgressBar = new GameProgressBar();
        this.gameProgressBar.x = 223;
        this.gameProgressBar.y = 185;
        this.addChild(this.gameProgressBar);

        this.balance = new InfoLabel('Balance', this.game.server.getBalanceText()) ;
        this.balance.x = 1075;
        this.balance.y = 683;
        this.addChild(this.balance);

        this.win = new InfoLabel('Win', '');
        this.win.x = 890;
        this.win.y = 683;
        this.addChild(this.win);

        this.freeSpeens = new InfoLabel('Free Spins', '0');
        this.freeSpeens.x = 146;
        this.freeSpeens.y = 683;
        this.addChild(this.freeSpeens);

        this.placeBetButton = this.game.objectFactory.createButton({
                default: 'SpinButtonDefault.png',
                hover: 'SpinButtonHover.png',
                pressed: 'SpinButtonPressed.png',
                disabled: 'SpinButtonDisabled.png',
            },
            () => {
                this.placeBetButtonHandler();
                this.game.soundPlayer.playSWX('playButtonSound');
                this.game.soundPlayer.playSWX('placeBetSound');
            }
        );
        this.placeBetButton.x = this.game.config.screenSize.width / 2 - this.placeBetButton.width / 2;
        this.placeBetButton.y = 612;
        this.addChild(this.placeBetButton);

        this.infoText = ObjectFactory.createText(`Tap the ball to auto-kick`, BottomPanelTextStyle);
        this.infoText.x = this.game.config.screenSize.width / 2;
        this.infoText.y = 780;
        this.infoText.alpha = 0.5;
        this.infoText.anchor.set(0.5);
        this.addChild(this.infoText);

        this.settingsButton = this.game.objectFactory.createButton({
                default: 'SettingsButtonDefault.png',
                hover: 'SettingsButtonHover.png',
                pressed: 'SettingsButtonPressed.png',
                disabled: 'SettingsButtonDisabled.png',
            },
            () =>{
                this.game.fms.goTo('settingGameSettings');
                this.game.soundPlayer.playSWX('buttonClickSound');
            }
        );
        this.settingsButton.x = 1138;
        this.settingsButton.y = 42;
        this.addChild(this.settingsButton);

        this.soundButton = this.game.objectFactory.createButton({
                default: 'SoundButtonDefault.png',
                hover: 'SoundButtonHover.png',
                pressed: 'SoundButtonPressed.png',
                disabled: 'SoundButtonDisabled.png',
            },
            this.toggleSound.bind(this)
        );
        this.soundButton.x = 1138;
        this.soundButton.y = 90;
        this.addChild(this.soundButton);

        this.soundButtonOff = this.game.objectFactory.createButton({
                default: 'SoundButtonDisabled.png',
                hover: 'SoundButtonDisabled.png',
                pressed: 'SoundButtonDisabled.png',
                disabled: 'SoundButtonDisabled.png',
            },
            this.toggleSound.bind(this)
        );
        this.soundButtonOff.x = 1138;
        this.soundButtonOff.y = 90;
        this.addChild(this.soundButtonOff);
        this.soundButton.visible = !this.game.soundPlayer.isMuted;
        this.soundButtonOff.visible = this.game.soundPlayer.isMuted;

        this.infoButton = this.game.objectFactory.createButton({
                default: 'InfoButtonDefault.png',
                hover: 'InfoButtonHover.png',
                pressed: 'InfoButtonPressed.png',
                disabled: 'InfoButtonDisabled.png',
            },
            () => {
                this.game.fms.goTo('showInfo');
                this.game.soundPlayer.playSWX('buttonClickSound');
            }
        );
        this.infoButton.x = 1138;
        this.infoButton.y = 137;
        this.addChild(this.infoButton);

        // collect button
        const collectButtonContainer = new Container();
        collectButtonContainer.x = 712;
        collectButtonContainer.y = 644;
        this.addChild(collectButtonContainer);

        this.collectButton = this.game.objectFactory.createButton({
                default: 'CollectButtonDefault.png',
                hover: 'CollectButtonHover.png',
                pressed: 'CollectButtonPressed.png',
                disabled: 'CollectButtonDisabled.png',
            },
            () => {
                this.collectButtonHandler();
                this.game.soundPlayer.playSWX('buttonClickSound');
            }
        );
        this.collectButton.disable();

        collectButtonContainer.addChild(this.collectButton);

        const collectText = ObjectFactory.createText('Collect', BottomPanelTextStyle);
        collectText.x = this.collectButton.width / 2;
        collectText.y = this.collectButton.height + 9;
        collectText.anchor.set(0.5);
        collectButtonContainer.addChild(collectText);

        const betButtonContainer = new Container();
        betButtonContainer.x = 440;
        betButtonContainer.y = 644;
        this.addChild(betButtonContainer);

        this.betButton = this.game.objectFactory.createButton({
                default: 'BetButtonDefault.png',
                hover: 'BetButtonHover.png',
                pressed: 'BetButtonPressed.png',
                disabled: 'BetButtonDisabled.png',
            },
            () => {
                this.game.fms.goTo('selectBet');
                this.game.soundPlayer.playSWX('buttonClickSound');
            }
        );

        betButtonContainer.addChild(this.betButton);

        const betText = ObjectFactory.createText('Bet' , BottomPanelTextStyle);
        betText.x = this.betButton.width / 2;
        betText.y = this.betButton.height + 9;
        betText.anchor.set(0.5);
        betText.alpha = 0.6;
        betButtonContainer.addChild(betText);

        this.betsLis = ObjectFactory.createList(this.game.server.betsList, this.game.server.currentBetIndex, 'Bet', undefined, BetsListTextStyle);
        this.betsLis.x = 331;
        this.betsLis.y = 683;
        this.betsLis.currentValueText.alpha = 0.6;
        this.betsLis.label.alpha = 0.6;
        this.betsLis.on('changedValue', this.onChangeBet.bind(this));
        this.addChild(this.betsLis);

        this.goalkeeper = new Goalkeeper();
        this.goalkeeper.x = this.game.config.screenSize.width / 2;
        this.goalkeeper.y = 606;
        this.addChild(this.goalkeeper);

        this.kickButton = this.game.objectFactory.createBallButton({
                default: 'ball.png',
                hover: 'ball.png',
                pressed: 'ball.png',
                disabled: 'ball.png',
            },
            '2_random_kick/2_random_kick',
            () =>{
                this.kickButtonHandler();
            }
        );

        this.kickButton.x = 550;
        this.kickButton.y = 619;
        this.kickButton.visible = false;
        this.addChild(this.kickButton);

        this.autoplayButton = new AutoplayButton();
        this.autoplayButton.x = 1038;
        this.autoplayButton.y = 512;
        this.autoplayButton.on('onClickStart', () => {
            this.game.fms.goTo('autoplaySettings');
            this.game.soundPlayer.playSWX('buttonClickSound');
        });
        this.autoplayButton.on('onClickStop', () => {
            this.autoplayButton.disable();
            this.stopAutoplay();
            this.game.soundPlayer.playSWX('buttonClickSound');
        });
        this.addChild(this.autoplayButton);

        this.targets = new GateTargets((targetNumber) => this.targetHandler(targetNumber));
        this.addChild(this.targets);

        this.loseAnimation = this.game.objectFactory.createLoseAnimation(this.game.config.screenSize);
        this.addChild(this.loseAnimation);

        this.winAnimation = this.game.objectFactory.createWinAnimation(this.game.config.screenSize);
        this.addChild(this.winAnimation);
    }

    toggleSound() {
        this.game.soundPlayer.playSWX('buttonClickSound');
        this.game.soundPlayer.toggleMute();
        this.soundButton.visible = !this.game.soundPlayer.isMuted;
        this.soundButtonOff.visible = this.game.soundPlayer.isMuted;
    }

    stopAutoplay() {
        this.autoplayButton.toStartButton();
        this.game.settings.autoBet = false;
        this.game.server.betsList = this.game.server.defaultBetsList;

        const betIndex = this.game.server.betsList.indexOf(this.game.settings.autoplay.bet);

        this.game.server.setBetIndex(betIndex);
        this.betsLis.setValues(this.game.server.betsList, betIndex);
        this.setUserBetMode();
    }

    onChangeBet(betIndex) {
        this.game.server.setBetIndex(betIndex);
        this.game.soundPlayer.playSWX('buttonClickSound');
    }
    placeBetButtonHandler() {}
    kickButtonHandler() {}
    targetHandler(targetNumber) {}

        async kickBallToTarget(targetNumber, isWin, currentMultiplier) {
            if (!this.game.settings.kickAnimation) {
                return this.showTargetsResultNumber(targetNumber, isWin, currentMultiplier);
            }

            this.ballAnimations = this.game.objectFactory.createBallAnimation(targetNumber);
            this.ballAnimations.currentFrame = 0;
            this.ballAnimations.loop = false;
            this.ballAnimations.visible = false;
            this.ballAnimations.animationSpeed = this.animationSpeed;

            this.addChild(this.ballAnimations);

            return Promise.all([
                Timer.wait(250 / this.animationSpeed).then(() =>{
                    this.ballAnimations.visible = true;
                    this.ballAnimations.play();
                }),
                    (new Promise((resolve) => {
                        this.ballAnimations.onComplete = () => {
                            this.ballAnimations.destroy();
                            this.game.soundPlayer.playSWX('scoredGoalSound');
                            this.showTargetsResultNumber(targetNumber, isWin, currentMultiplier);
                            if (!isWin) {
                                this.ballFlightFromTarget(targetNumber)
                                this.game.soundPlayer.playSWX('kickBallSound');
                            }
                            resolve();
                        };
                    })),
                 ( async() => {
                     if (!isWin) {
                        await this.goalkeeper.catchTheBall(targetNumber);
                    } else {
                         await this.goalkeeper.missTheBall(targetNumber);
                     }
                 })()
          ]);
        }

    async showTargetsResultNumber(targetNumber, isWin, currentMultiplier) {
        if (isWin) {
           await this.targets.showWin(targetNumber, currentMultiplier);
        } else  {
            await this.targets.hideAll();
        }
    }

    async goToNextLevel(target, currentMultiplier) {
        await this.targets.winToPosition(target, currentMultiplier, this.gameProgressBar.getCurrentPointPosition());
        await this.gameProgressBar.toNextStep(currentMultiplier);
    }

    setBalance(value) {
        this.balance.setValue(value);
    }

    reset() {
        this.gameProgressBar.reset();
        this.win.setValue('');
        this.targets.showAll();
        this.targets.reset();
    }

    setWin(win) {
        this.win.setValue(win ? formatCurrency(win) : '');
    }

    async showTargets() {
        await  this.targets.showAll();
    }

    async selectTarget(targetNumber) {
        await this.targets.selectTarget(targetNumber);
    }

    async playLoseAnimation() {
        this.loseAnimation.visible = true;
        await this.loseAnimation.play();
    }

    async playWinAnimation() {
        this.loseAnimation.visible = true;
        await this.winAnimation.play(this.game.server.roundWin);
    }

   async updateBalance(balance) {
        this.balance.setValue(balance);
    }

    setAnimationSpeed(speed) {
        this.animationSpeed = speed;
        this.targets.animationSpeed = speed;
        this.goalkeeper.animationSpeed = speed;
        this.gameProgressBar.animationSpeed = speed;
    }

    setAutoBetMode() {
        this.betButton.disableInteractive();
        this.kickButton.disableInteractive();
        this.targets.disableInteractive();
        this.collectButton.disableInteractive();
        this.betsLis.disableInteractive();
        this.placeBetButton.disableInteractive();
    }

    setUserBetMode() {
        this.betButton.enableInteractive();
        this.kickButton.enableInteractive();
        this.targets.enableInteractive();
        this.collectButton.enableInteractive();
        this.betsLis.enableInteractive();
        this.placeBetButton.enableInteractive();
    }

    async ballFlightFromTarget(targetNumber) {
        const TargetPositionsBytTargetNumber = {
            0: {x: -100,  y: this.game.config.screenSize.height * 0.5},
            1: {x: -200, y: -200},
            2: {x: this.game.config.screenSize.width / 2, y: -200},
            3:  {x: this.game.config.screenSize.width + 200, y: -200},
            4:  {x: this.game.config.screenSize.width + 100, y: this.game.config.screenSize.height * 0.5},
        };
        const time = 300;
        const to = TargetPositionsBytTargetNumber[targetNumber];
        const ball = ObjectFactory.createSpriteFromSheet('ball.png');
        ball.x = this.targets.getTargetGlobalPosition(targetNumber).x;
        ball.y = this.targets.getTargetGlobalPosition(targetNumber).y;
        ball.anchor.set(0.5);
        ball.scale.set(0.5);
        ball.visible = false;

        this.addChild(ball);

        return new Promise(resolve => {

            ball.visible = true;

            Promise.all([
                ValueTween.to(ball, {key: 'x', to: to.x}, time),
                ValueTween.to(ball, {key: 'y', to: to.y}, time),
                ValueTween.to(ball, {key: 'alpha', to: 0}, time),
                ValueTween.to(ball, {key: 'scale', to: 1.5, from: 0.5, setter: value => ball.scale.set(value)}, time)
            ]).then(() => {
                ball.destroy();
                resolve();
            });
        });
    }

    disable() {
        this.placeBetButton.disable();
        this.betButton.disable();
        this.kickButton.disable();
        this.targets.disable();
        this.collectButton.disable();
        this.betsLis.disable();
        this.autoplayButton.disable();
    }

    restore() {
        this.gameProgressBar.restore(this.game.server.rounds);
    }
}
