import {Connection} from "./Connection";
import {roundToTwo} from "./utils";
import {ErrorMessageError} from "./errors/ErrorMessageError";

const SERVER_URL = 'https://luckykick.back-dev.com';

export class OnlineMode extends Connection {
    constructor({session_uuid, demo = undefined, ...otherOptions}) {
        super(otherOptions);

        this.demo = demo;
        this.session_uuid = session_uuid;
        this.gameSession = null;
        this.placeBetURL = `${SERVER_URL}/game`;
        this.kickURL = `${SERVER_URL}/kick`;
        this.collectWinningsURL = `${SERVER_URL}/cash-out`;
        this.initURL = `${SERVER_URL}/player`;
    }

    async init() {
        const result = await this.postRequest(this.initURL, {session_uuid: this.session_uuid, balance: this.balance}, 'GET');

        if (result.game_session) {
            this.currentBetIndex = this.betsList.indexOf(result.bet);
            this.gameSession = result.game_session;

            const rounds = result.kicks_info.multies.map((multiplier, index) => {
               return {
                     multiplier,
                     win: result.kicks_info.wins[index]
                };
            });

            this.restore(result.balance, result.bet, rounds);
        }
    }

    async placeBet() {
        await super.placeBet();

        const betAmount = this.getBet();

        this.reset();
        const result = await this.postRequest(this.placeBetURL, {
            balance: this.balance,
            bet: this.getBet(),
            session_uuid: this.session_uuid,
            demo: this.demo,
        });

        this.gameSession = result.game_session;
        this.balance -= betAmount;
    }

    async playRound(targetNumber) {
        if (this.winStreak >= 6) {
            throw new Error('Maximum win streak reached. Collect your winnings.');
        }

        const  {kick} = await this.postRequest(this.kickURL, {
            kick_level: this.winStreak,
            session_uuid: this.session_uuid,
            game_session: this.gameSession,
            demo: this.demo,
        });
        const isWin = kick && kick.is_win;
        let multiplierIncrease = 0;
        let winnings = 0;

        if (isWin) {
            multiplierIncrease = kick.multi;
            winnings = kick.win;
        }

        this.applyRoundResult(isWin, targetNumber, multiplierIncrease, winnings);

        return this.roundResult;
    }

    async collectWinnings() {
        const {balance} = await this.postRequest(this.collectWinningsURL, {
            session_uuid: this.session_uuid,
            game_session: this.gameSession,
            demo: this.demo,
        });
        const winnings = balance - this.balance;

        this.balance = balance;

        return winnings;
    }

    async postRequest(url, urlArguments, method = 'POST') {
        url += '?';

        for (const key in urlArguments) {
            if (urlArguments[key] !== undefined) {
                url += `&${key}=${urlArguments[key]}`;
            }
        }

        const response = await fetch(url, {
            method
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result =  await response.json();

        if (response.custome_message) {
            throw new ErrorMessageError(response.custome_message);
        }

        //    "status": "success",
        if (result.status === 'success' || result.game_session || result.balance !== undefined) {
            return result;
        } else {
            throw new Error(result.message);
        }
    }
}
