import {GameState} from "../GameState";

export class DisabledState extends GameState {
    onEnter() {
        this.game.scene.placeBetButton.visible = true;
        this.game.scene.disable();
    }

    async onExit() {
    }
}
